/* General styles for the banner */
.banner {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -10;
}

.banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.459);
  z-index: 10;
}

/* Styles for larger screens (desktops and laptops) */
/* @media (min-width: 1024px) {
  .banner {
    height: 100vh;
  }

  .overlay {
    height: 100vh;
  }
} */

/* Styles for tablets and smaller laptops */
@media (min-width: 768px) and (max-width: 1023px) {
  .banner {
    height: 60vh;
  }

  .overlay {
    height: 60vh;
  }
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .banner {
    height: 50vh;
  }

  .overlay {
    height: 50vh;
  }
}
