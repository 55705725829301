/* General styling for mobile responsiveness */
@media (max-width: 768px) {
    /* Adjust the main title */
    .mt-[120px] {
      margin-top: 60px;
    }
    .text-5xl {
      font-size: 2rem; /* Adjust the font size for mobile */
    }
  
    /* Handle Flexbox adjustments */
    .w-[70%] {
      width: 90%; /* Adjust width for mobile */
    }
  
    .w-[50%] {
      width: 100%; /* Full width on mobile */
    }
  
    /* Adjust margins and text alignment */
    .my-6 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    /* Ensure images resize properly */
    img {
      width: 100%;
      height: auto;
    }
  
    /* Adjust HaveAScent component for mobile */
    .have-a-scent-container {
      flex-direction: column;
      align-items: center;
    }
  
    .text-xl {
      font-size: 1rem; /* Adjust font size for mobile */
    }
  }
  
  /* General desktop styling can remain as is */
  